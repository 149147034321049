import { createAsyncThunk } from "@reduxjs/toolkit";
import myContractServices from "./../../Services/MycontractServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { Getsubobligationdata } from "../MyObligation/MyObligation.Action";
import { getTaskactivitylist, getTaskdetails } from "../MyTask/MyTask.Action";
import {
  getDetails,
  getStaffList,
  getVendordetailsList,
} from "../Taskmanagement/Taskmanagement.Action";
import { setDrawerVisible } from "../Taskmanagement/Taskmanagement.Slice";
import { onMenuClickchanges } from "../../globals/layout/layout.slice";

export const getContracts = createAsyncThunk(
  "contract/getContracts",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getContractsAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTask = createAsyncThunk(
  "task/createTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await myContractServices.createTaskAPI(data);
      // thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContractsforcompany = createAsyncThunk(
  "contract/getContractsforcompany",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getContractsforcompanyAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getbuckets = createAsyncThunk(
  "contract/getbuckets",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getbucketsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getVendorLists = createAsyncThunk(
  "contract/getVendorLists",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getVendorListsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContractByVendor = createAsyncThunk(
  "contract/getContractByVendor",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getContractByVendorAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAddendumTask = createAsyncThunk(
  "contract/getAddendumTask",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getAddendumTaskAPi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContractDetails = createAsyncThunk(
  "contract/getContractDetails",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getContractDetailAPi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getcontractVerify = createAsyncThunk(
  "contract/getcontractVerify",
  async (data, thunkAPI) => {
    try {
      const { form_data } = data;
      let res = await myContractServices.getgetcontractVerifyAPI(form_data);
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAcceptorrejectobligation = createAsyncThunk(
  "contract/getAcceptorrejectobligation",
  async ({ data, obgpageSubobligationData }, thunkAPI) => {
    try {
      let res = await myContractServices.getAcceptorrejectobligationAPI(data);
      thunkAPI.dispatch(Getsubobligationdata(obgpageSubobligationData));
      // responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createAddendumforTasks = createAsyncThunk(
  "task/createAddendumforTasks",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await myContractServices.createAddendumforTasksAPI(data);

      thunkAPI.dispatch(setDrawerVisible({ visible: true, record: res.data }));
      thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
      thunkAPI.dispatch(
        getVendordetailsList({
          sortBy: "DESC",
          status: true,
          orderBy: "createdAt",
          is_from_task: true,
        })
      );
      thunkAPI.dispatch(getStaffList());
      thunkAPI.dispatch(getTaskactivitylist({ taskId: res.data._id }));
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(onMenuClickchanges("task_management"));
      localStorage.setItem("dynamic_url_path", `/task_management`);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createRenewofTasks = createAsyncThunk(
  "task/createRenewofTasks",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await myContractServices.createRenewofTasksAPI(data);

      thunkAPI.dispatch(onMenuClickchanges("task_management"));
      localStorage.setItem("dynamic_url_path", `/task_management`);

      thunkAPI.dispatch(setDrawerVisible({ visible: true, record: res.data }));
      thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
      thunkAPI.dispatch(
        getVendordetailsList({
          sortBy: "DESC",
          status: true,
          orderBy: "createdAt",
          is_from_task: true,
        })
      );
      thunkAPI.dispatch(getStaffList());
      thunkAPI.dispatch(getTaskactivitylist({ taskId: res.data._id }));
      thunkAPI.dispatch(getTaskdetails(taskPagination));

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRenewalsInfoOfContract = createAsyncThunk(
  "contract/getRenewalsInfoOfContract",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getRenewalsInfoOfContractAPI(data);
      // thunkAPI.dispatch(Getsubobligationdata(obgpageSubobligationData));
      // responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getarchiveContracts = createAsyncThunk(
  "contract/getarchiveContracts",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getarchiveContractsAPI(data);
      // thunkAPI.dispatch(Getsubobligationdata(obgpageSubobligationData));
      // responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createChildContracts = createAsyncThunk(
  "contract/createChildContracts",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      let res = await myContractServices.createChildContractsAPI(data);
      thunkAPI.dispatch(onMenuClickchanges("task_management"));
      localStorage.setItem("dynamic_url_path", `/task_management`);

      thunkAPI.dispatch(setDrawerVisible({ visible: true, record: res.data }));
      thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
      thunkAPI.dispatch(
        getVendordetailsList({
          sortBy: "DESC",
          status: true,
          orderBy: "createdAt",
          is_from_task: true,
        })
      );
      thunkAPI.dispatch(getStaffList());
      thunkAPI.dispatch(getTaskactivitylist({ taskId: res.data._id }));
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getFamilyContractInfo = createAsyncThunk(
  "contract/getFamilyContractInfo",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.getFamilyContractDetailsAPI(data);
      // thunkAPI.dispatch(Getsubobligationdata(obgpageSubobligationData));
      // responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreateRelationwithContracts = createAsyncThunk(
  "contract/CreateRelationwithContracts",
  async (data, thunkAPI) => {
    try {
      let res = await myContractServices.CreateRelationwithContractsAPI(data);
      thunkAPI.dispatch(
        getFamilyContractInfo({
          contractId: res.data._id,
        })
      );
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
